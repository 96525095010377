<template>
  <div>
    <div class="contentFrame">
      <div class="leftSide">
        <div class="subinfotext">サンタクロース大使館利用規約</div>

        <div class="sentent_r1">
          お客様は、本サイトの閲覧、利用した時点で本利用規約を受諾しこれに拘束されることになります。尚、当社利用規約は、事前通知なく、改訂される場合がございますので、必ずご注文時にご確認下さい。
        </div>
        <div class="sentent_g1">
          <h2>総則</h2>
          『本サイト』とは弊社が管理運営する、インターネット上の商品売買等のサービスの提供を目的としたサンタクロースからの手紙を指します。<br />
          弊社が本サイトを通じて提供するサービスを『本サービス』と規定します。<br />
          『お客様』とは当サイトを閲覧される方および当サイトを通じて商品のご注文・ご購入をされる方、メールマガジン等のサービスをご利用される方とし、このご利用規約を受諾されたものとみなします。<br />
          弊社が別途規定する個別規定、または弊社が随時本サイト内に掲示、またはお客様に対し通知する追加規定並びに弊社ホームページ上に掲示
          する「プライバシーポリシー」は、本規約と不可分一体のものとして本規約の一部を構成します。なお、以後本規約および個別規定ないし追加規定並びに「プラ
          イバシーポリシー」を総称して、『本規約等』といいます。<br />
          本規約と個別規定または追加規定並びに「プライバシーポリシー」が異なる場合は、個別規定または追加規定並びに「プライバシーポリシー」が本規約に優先して適用されるものとします。<br />
          弊社はお客様に事前の通知を行うことなく、本規約等および本サイトのサービス内容を変更することができるものとします。
          この場合、変更後の本規約等または本サイトのサービス内容を弊社が適当と判断する方法で告知するものとします。<br />

          <h2>サンタクロースからの手紙について</h2>
          サンタクロースからの手紙は、旧ラップランド州当時よりの公認サンタクロースとの許諾により、サンタクロースより毎年送られる情報、資料に基づき、国内のサンタクロース大使館で印字、宛名シートの封入・封緘が行われております。尚、お届けの手紙につきましては、サンタクロース大使館にて封入・封緘後、クロネコヤマト、クロネコゆうパケット便を利用しお届けいたします。クロネコゆうパケット便を利用することにより、配達状況をご確認頂き、安全・安心を最重要項目とし、未着等の事故の無い様に細心の注意を払いお届けさせて頂きます。サンタクロースポステージオフィースコルヴァトントゥリの由来につきましては、2009年、当時のラップランド州政府知事、ティモコルバァ氏と当時のオフィシャルサンタクロースによりサンタクロースからの手紙に対する名称使用の推薦を受け局長は当時のサンタクロースが務めております。<br />
          なお、毎年フィンランド郵政発行のクリスマス切手には、サンタクロースポステージオフィースコルヴァトゥントゥリ
          の特別な消印でお届けいたしておりましたが、2019年度の「サンタクロースからの手紙」につきまして、2020年東京オリンピックを前に日本政府機関によるテロ防止対策の一環として海外より日本国内に送られる国際郵便物にも2019年より厳しい検査体制が加わり、子供たちの夢を育む、「サンタクロースからの手紙」も、こうした規制強化の対象となり、万が一事件・事故等の発生によって未着・遅延・破損等の影響を受ける可能性も否定できない状況となります。<br />
          こうした中、サンタロース大使館では、フィンランド現地との話し合いを行い、万国郵便条約に基づくフィンランド郵政発行の切手の使用を控え、国際郵便物としてのお届けを控え、切手型シール(切手ではありません)にて「サンタクロースからの手紙」をお届け致します。<br />

          <h2>オプション商品と送料について</h2>
          オプション商品のうち、追加送料がかかるオプションをご選択された場合は、地域ごとに別途送料が発生します。詳しくは地域別送料一覧表に記載の運賃表に記載いたしております。<br />
          一つのお手紙に追加送料のかかる複数のオプションを選択していただいた場合には、同梱して発送されますので、送料は一か所に対してのみかかります。<br />
          複数のお手紙に追加送料のかかるオプションをそれぞれ選択して頂いた場合は、お手紙のご注文ごとにそれぞれ別の発送となりますので、送料は該当するお手紙のご注文の数だけかかります。複数のお手紙の送付先が同じ場合の同梱による発送は受け付けておりませんのでご了承ください。<br />
          お手紙、オプションはクリスマスまでに到着するように発送させていただきます。お客様からの着日指定などはお受けできませんのでご了承ください。<br />
          オプション商品には万全を期しておりますが、万一破損等が見つかった場合には商品到着から１週間以内に当社までご連絡をお願い申し上げます。商品の代替品発送をもってご対応をさせていただきます。商品のキャンセル・ご返金は致しかねますのでご了承ください。<br />

          <h2>免責事項</h2>
          販売価格はご注文時の価格になります。ご注文後あるいは納品までの期間に価格変更が生じた場合にも、販売価格の変更は行わないものとします(記載間違いを除く)。<br />
          お客様のご注文は、弊社が指定する方法にて弊社が受理した場合のみ有効です。
          ショッピングカート以外の注文は出来ません。システムのトラブルや、その他都合により弊社が注文を受理出来ない場合も有効とはなりません。<br />
          納期の厳守には細心の注意を払っておりますが、システムトラブル、配送状況、その他都合により、納期の変動が起こり得ることをご了承ください。それによりお客様または第3者に損害が発生したとしても責任を負いません。<br />
          掲載情報は予告なく変更又は、更新されることがあり、当社は本サイトに記載された製品情報やプログラムを予告なしに変更することができます。<br />
          お客様からのご注文後のキャンセル変更追加はお受付しておりません。ご注文の際は、十分にご検討の上、ご注文頂きますようお願い申し上げます。<br />
          上記の事由に関わらず、本サイトの記載事項・リンク先にて発生した損害に付きましては一切の責任を負いかねます。<br />

          <h2>著作権について</h2>
          当ホームページで提供される全ての情報に関する権利及び文章、映像、画像、音声などの著作権並びに肖像権は全て当社または当社が使用許諾を受けている権利者に帰属するものとします。<br />

          お客様は、権利者の許諾を得ないで、いかなる方法においても、本サービスを通じて弊社により提供される情
          報またはファイルについて、著作権法で定める顧客個人の私的利用の範囲を超えて、使用することはできないものとします。前項の規定にかかわらず、権利者と
          問題が発生した場合、お客様は、自己の費用と責任においてかかる問題を解決するとともに、弊社に何等の迷惑または損害を与えないものとします。但し、弊社
          が本サービスを通じて提供する情報又はファイルについて権利者との間に問題が生じた場合は、弊社が自己の費用と責任においてかかる問題を解決するととも
          に、お客様に何等の迷惑または損害を与えないものとします。

          <h2>損害責任</h2>
          本サイトへのアクセス、使用については、ご利用者様の判断で行うことになります。当社並びに、本サイトの製作にかかわる第三者も、本サイトの閲覧、アクセスにより生ずるいかなる損害、障害に関して、責任を負いません。<br />

          <h2>サービス内容の変更について</h2>
          当社は、本サイトのサービスを事前にお客様に通知することなく、いつでも変更、停止又は、中止することが出来るものとします。

          <h2>本サイト運営の中止等について</h2>
          当社は、本サイトのシステム障害、メンテナンス等の際に事前の通知なく、一時的又は長期的に中止することがございます。尚、本サイトの中止により生じる障害にも一切責任を負わないものとします。

          <h2>パスワードについて</h2>
          利用者は自らが設定したパスワードを使用するものとし、自己責任においてパスワードの管理を行うものとします。利用者のパスワードが第三者による不正使用により損害が生じた場合にも、その一切の責任を負わないものとします。

          <h2>個人情報の管理、保管</h2>
          本サイトでは、商品のご購入、会員登録、アンケート回答などの場面において、お客様の個人情報を入力して
          いただき、データベースへ登録致します。ご登録情報は、お客様の当社においての商品のご購入状況や特価情報のご案内など、お客様に有益で便利なサービスを
          ご提供するために使用させていただきます。また、登録情報は、第三者へ漏洩することの無いように、管理、保管するよう努めます。

          <h2>販売価格及び注文の取り消しについて</h2>
          申し込み時のお客様の個人情報が虚偽であった場合。<br />
          ご注文後10日以内にご入金が確認出来なかった場合。（注文受付期間は、10日間）<br />
          弊社がもともと数量を限定している商品について、その限度量を超えて注文が
          入ったものであるとき。<br />
          その他、取り消すことについてやむを得ない事由があると認められる場合。

          <h2>返品、返金について</h2>
          購入頂いた商品の返品、返金には、応ずることは出来ません。事前に十分ご検討の上ご購入下さい。

          <h2>納期、配送について</h2>
          当社は、利用者からのご注文を受領した時点で、迅速な配送が出来るよう努めておりますが、天災、システム
          トラブル、その他予期せぬ事態により配送が遅れる場合がございます。これに関して、直接的及び間接的にご利用者又は、第三者に発生した損害についていかな
          る責任も負いません。又、価格表記に間違えが合った場合には、当方の判断にて出荷出来ない場合がございます。その際は、直ちにその旨、ご連絡を申し上げま
          す。

          <h2>損害賠償責任</h2>
          当社は、業務の遂行にあたり、商品の間違い、荷物の滅失、毀損が発生した場合に限り、お客様の指示により代替品の無償発送を行うものとします。ただし、お届け先の住所に宛名が無い場合は、ご注文主に商品を転送する事とします。

          <h2>未成年者のご利用</h2>
          未成年者が本契約に同意いただく場合には、親権者の同意がなければなりません。

          <h2>準拠法</h2>
          本規約の成立、効力、履行および解釈に関しては、日本法が適用されるものとします。

          <h2>管轄裁判所</h2>
          本サービスに関連して、顧客と弊社との間で紛争が生じた場合には、当事者間において誠意をもって解決するものとします。協議をしても解決しない場合、宇都宮地方裁判所又は大田原簡易裁判所を専属的合意裁判所とすることに合意します。

          <h2>その他</h2>
          この保証規約は、日本国内においてのみ有効です。<br />
          本サイトによるサービスを提供する機器の故障、トラブル及び、通信回線の異常等によりご利用者のデータが消失、紛失した場合、当社は一
          切の責任を負いかねます。また、システム障害等により利用者様の情報を紛失、消滅、遅延する場合がございます。当社ではこれらに関する損害の一切の責任を
          負いません。
          <br />
          <br />
          当社は、このご利用規約の内容の全部又は一部を改定することがあります。
          改定した場合には、このホームページの掲載内容に反映すること等により公表いたします。<br />
          <br />
          <br />
          制定・施行：平成２５年０９月０１日<br />
          <br />
          株式会社スカットコミュニケーションズ（サンタクロース大使館）<br />
          〒325-0303 栃木県那須郡那須町高久乙3584-4<br />
          代表取締役 木村 安晴
        </div>
      </div>
      <div class="rightSide">
        <sideContents />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$parent.middle = 2;
  },
};
</script>

<style scoped lang="scss">
$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

.subinfotext {
  color: #454552;
  font-size: 1.5em;
  font-weight: bold;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/ilst1.png") no-repeat left center /
    contain;
  @include sp {
    padding-left: 10vw;
    font-size: 1em;
  }
}
.sentent_r1 {
  color: #d72e23;
  line-height: 1.7;
  margin: 2em 0;
}
.sentent_g1 {
  text-align: left;
  color: #454552;
  line-height: 1.7;
  margin: 2em 0;
}
.subsent2 {
  margin-bottom: 2em;
  text-align: left;
  border: solid 3px #d72e23;
  padding: 1em;
  h3 {
    color: #d72e23;
    padding: 2em 0;
  }
  h4 {
    color: #d72e23;
    margin: 1em 0;
    padding-left: 1em;
    border-left: double 10px #d72e23;
  }
}
.centeimg1 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 33%;
    max-width: 100%;
  }
}
.centeimg2 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 90%;
    max-width: 100%;
  }
}
</style>